import React from "react"
import styled from "styled-components"
import theme from "../styles/theme"
import { Button } from "../styles/ui"
import { RiMailSendLine } from "react-icons/ri"
import { email } from "../content/content"
import { darken } from "polished"
import fonts from "../styles/fonts"

const SSection = styled.section`
  background: ${darken(0.065, theme.bg)};
  border-top: 1px solid ${theme.secondaryAccent};
  border-bottom: 1px solid ${theme.secondaryAccent};
  position: sticky;
  bottom: 0;
  display: flex;
  justify-content: center;
  padding: 0.75em;
  font-size: 90%;
  .text-block {
    font-family: ${fonts.hand};
    font-size: 2em;
    margin-right: 0.5em;
  }
`

const StickyCTA = ({ text, buttonText, subject, extHref, buttonIcon }) => {
  return (
    <SSection>
      <div className="text-block">{text}</div>

      {!!subject && (
        <Button as="a" href={`mailto:${email}?subject=${subject}`}>
          {buttonText} <RiMailSendLine />
        </Button>
      )}
      {!!extHref && (
        <Button as="a" href={extHref} target="_blank" rel="noreferrer">
          {buttonText} {buttonIcon}
        </Button>
      )}
    </SSection>
  )
}

export default StickyCTA
