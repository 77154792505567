import React from "react"
import Layout from "../components/Layout"
import banner from "../images/gallery/hq21.jpg"
import { RiMailSendLine } from "react-icons/ri"
import VideoBlock from "../components/VideoBlock"
import TrackVisibility from "react-on-screen"
import StickyCTA from "../components/StickyCTA"
import { email } from "../content/content"
import shutupandtakemymoney from "../images/shutupandtakemymoney.png"
import promoPackage from "../images/icons/icon_promopackage.png"
import promoPackageArrows from "../images/icons/icon_promopackage-arrows.png"
import { darken } from "polished"
import theme from "../styles/theme"
import Banner from "../components/Banner"
import HalfBlock from "../components/HalfBlock"
import Cards from "../components/Cards"
import Section from "../components/Section"
import { Button, CenteredBox, FadeUp, StaticCenteredBox } from "../styles/ui"
import { BsArrowRight } from "react-icons/bs"
import Icon3D from "../images/icons/icon_3d.png"
import Iconwebcontent from "../images/icons/icon_copywriting.png"
import Iconphotography from "../images/icons/icon_production.png"
import IconEvent from "../images/icons/icon_events.png"
import Iconwebsite from "../images/icons/icon_webdesign.png"
import IconStudio from "../images/icons/icon_studio2.png"
import IconEquipment from "../images/icons/icon_studio.png"
import IconMovie from "../images/icons/icon_chair.png"
import SEOpt from "../components/SEOpt"

const bannerImage = {
  src: banner,
  altText: "DJ booth at Rocketship HQ",
}

const services = [
  {
    title: "Video production",
    text:
      "We will produce, film and edit an awesome music or promo video for you",
    img: {
      src: IconMovie,
      altText: "Movie icon",
    },
  },
  {
    title: "Photography",
    text:
      "Our photographers will capture you and your products in the best light",
    img: {
      src: Iconphotography,
      altText: "Camera icon",
    },
  },
  {
    title: "Studio rental",
    text: "The studio at Rocketship HQ offers a unique experience",
    img: {
      src: IconStudio,
      altText: "Studio icon",
    },
    button: {
      text: "Check it out",
      icon: <BsArrowRight />,
      to: "/studio",
    },
  },
  {
    title: "Small-scale manufacturing",
    text:
      "We can deisgn and manufacture whatever you need using 3D-printing, resin printing and laser engraving",
    img: {
      src: Icon3D,
      altText: "3D-printer icons",
    },
  },
  {
    title: "Equiptment rental",
    text: "Rent gear for your multimedia projects. We have some cool stuff ;)",
    img: {
      src: IconEquipment,
      altText: "Equipment icon",
    },
  },
  {
    title: "Design & development",
    text: "We will design and build you an awesome website or app",
    img: {
      src: Iconwebsite,
      altText: "Website",
    },
  },
  {
    title: "Content creation",
    text: "Our copywriters help you compose and improve upon your content",
    img: {
      src: Iconwebcontent,
      altText: "Webcontent icon",
    },
  },
  {
    title: "Event Planning",
    text: "We create events packed with stunning visual experiences",
    img: {
      src: IconEvent,
      altText: "Event icon",
    },
  },
]

const Services = () => {
  return (
    <Layout>
      <SEOpt title="Services" />
      <Banner {...bannerImage} />
      <Section background={darken(0.01, theme.bg)}>
        <CenteredBox>
          <div className="section-head-wrap">
            <TrackVisibility>
              <FadeUp>
                <h1>Services</h1>
              </FadeUp>
            </TrackVisibility>
            <TrackVisibility>
              <FadeUp>
                <p className="sub-head">
                  We collaborate with a wide array of creatives to help you
                  reach your audience in the most exciting way possible. Here's
                  what we offer
                </p>
              </FadeUp>
            </TrackVisibility>
          </div>
        </CenteredBox>
        <StaticCenteredBox>
          <Cards items={services} />
        </StaticCenteredBox>
      </Section>
      <Section
        className="notebook-bg"
        style={{ borderBottom: `1px solid ${theme.secondaryAccent}` }}
      >
        <CenteredBox>
          <div
            className="hand-font"
            style={{ textAlign: "center", color: theme.accent }}
          >
            <TrackVisibility>
              <FadeUp>
                <h2 style={{ marginBottom: 0 }}>
                  Set your goals &nbsp; {" >> "} &nbsp; Free consultation &nbsp;{" "}
                  {" >> "} &nbsp; Let us do the rest
                </h2>
              </FadeUp>
            </TrackVisibility>
            <TrackVisibility>
              <FadeUp>
                <img src={promoPackageArrows} alt="Arrows" />
              </FadeUp>
            </TrackVisibility>
            <TrackVisibility>
              <FadeUp>
                <a href={`mailto:${email}?subject=I want a promo package!`}>
                  <img
                    src={promoPackage}
                    alt="Full promo package"
                    className="hover-zoom"
                  />
                </a>
              </FadeUp>
            </TrackVisibility>
          </div>
        </CenteredBox>
      </Section>
      <StickyCTA text="Interested?" buttonText="Get in touch" />
      <VideoBlock
        title="Kevin Valentine at Rocketship HQ"
        subtitle="Kevin's DJ set we reacorded at the Warehome"
        videoTitle="Special thanks to Kevin Valentine"
        videoUrl="https://www.youtube.com/embed/ApQjmxvqTdc"
        autoplay={false}
      />
      <HalfBlock
        background={darken(0.02, theme.bg)}
        imgSrc={shutupandtakemymoney}
        title="Interested?"
        titleLarger="Shoot us a message!"
        text="Let's talk about what we can do for you"
        cta={
          <Button
            as="a"
            href={`mailto:${email}?subject=I want a free consultation!`}
          >
            Schedule a free consultation <RiMailSendLine />
          </Button>
        }
      />
    </Layout>
  )
}

export default Services
