import React from "react"
import styled from "styled-components"

const SBanner = styled.section`
  position: relative;
  .inner-wrap {
    overflow: hidden;
    position: relative;
  }
  .banner-overlay {
    background: linear-gradient(rgb(0 0 0 / 84%), transparent);
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    height: 50%;
    z-index: 1;
    pointer-events: none;
  }
  .main-img {
    display: block;
    width: 100%;
    transition: transform 2s;
  }
  &:hover {
    .main-img {
      transform: scale(1.1, 1.1);
    }
  }
  @media (min-width: 920px) {
    .inner-wrap {
      height: 65vh;
    }
    .main-img {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }
`

const Banner = ({ src, altText }) => {
  return (
    <SBanner>
      <div className="inner-wrap">
        <div className="banner-overlay"></div>
        <img className="main-img" src={src} alt={altText} />
      </div>
    </SBanner>
  )
}

export default Banner
